import React, { useEffect, useState, useCallback } from "react";

import axios from "axios";

const Routes = [
  "https://wheelpair.com",
  "https://wheelpair.com/search",
  "https://wheelpair.com/blog",
  "https://wheelpair.com/for-mechanics",
  "https://wheelpair.com/about-us",
];

function SiteMap(props) {
  const [xml, setXML] = useState();

  const fetchBlogPosts = useCallback(() => {
    axios.get("https://api2.wheelpair.com/website/blogs").then((res) => {
      const doc = document.implementation.createDocument("", "", null);
      const urlset = doc.createElement("urlset");
      urlset.setAttribute(
        "xmlns",
        "http://www.sitemaps.org/schemas/sitemap/0.9"
      );

      for (let i = 0; i < Routes.length; i++) {
        const url = doc.createElement("url");
        const loc = doc.createElement("loc");
        loc.innerHTML = Routes[i];

        url.appendChild(loc);
        urlset.appendChild(url);
      }

      for (let i = 0; i < res.data.blogposts.length; i++) {
        let title = res.data.blogposts[i].title;
        title = title.toLowerCase();
        title = title.replace(/[^A-Za-z0-9 ]/g, "");
        title = title.replace(/\s{2,}/g, " ");
        title = title.replace(/\s/g, "-");

        const url = doc.createElement("url");
        const loc = doc.createElement("loc");
        loc.innerHTML = "https://wheelpair.com/blog/" + title;

        url.appendChild(loc);
        urlset.appendChild(url);
      }

      doc.appendChild(urlset);
      var oSerializer = new XMLSerializer();
      var xmltext = oSerializer.serializeToString(doc);
      xmltext = '<?xml version="1.0" encoding="UTF-8"?>' + xmltext;

      setXML(xmltext);
    });
  }, [setXML]);

  useEffect(fetchBlogPosts, []);

  return xml && true ? xml : <div />;
}

export default SiteMap;
